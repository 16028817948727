.alert{
    border: 0;
    border-radius: 0;
    color: #FFFFFF;
    padding: 10px 15px;
    font-size: 14px;

    & .close{
        font-size: $font-size-large;
        color: $white-color;
    }

    .container &{
        border-radius: 4px;

    }
    .navbar &{
        border-radius: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 85px;
        width: 100%;
        z-index: 3;
    }
    .navbar:not(.navbar-transparent) &{
        top: 70px;
    }
    .alert-icon{
        display: block;
        font-size: 30px;
        left: 15px;
        position: absolute;
        top: 50%;
        margin-top: -22px;
    }

    &.message{
        padding-right: 60px;
    }
    i{
        position: relative;
        font-size: 20px;
        top: 5px;
        margin-top: -5px;
        font-weight: $font-weight-bold;
    }

}
.alert-info{
    background-color: $bg-info;
}
.alert-success {
    background-color: $bg-success;
}
.alert-warning {
    background-color: $bg-warning;
}
.alert-danger {
    background-color: $bg-danger;
}

.carousel-wrapper {
    width: 1000px;
    margin: auto;
    position: relative;
    text-align: center;
    font-family: sans-serif;
    padding: 15px;
}

.owl-carousel .owl-nav {
    overflow: hidden;
    height: 0px;
}

.owl-carousel .owl-item:hover img {
    transform: translateY(-2px) !important;
}

.img-thumbnail-border {
    background-color:#fbcc70
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: #5110e9;
    transform: translateY(-10px);
}

.owl-carousel .item {
    text-align: center;
}

.owl-carousel .nav-button {
    height: 50px;
    width: 25px;
    cursor: pointer;
    position: absolute;
    top: 110px !important;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
    pointer-events: none;
    opacity: 0.25;
}

.owl-carousel .owl-prev {
    left: -35px;
}

.owl-carousel .owl-next {
    right: -35px;
}

.owl-theme .owl-nav [class*=owl-] {
    color: #ffffff;
    font-size: 39px;
    background: #000000;
    border-radius: 3px;
}

.owl-carousel .prev-carousel:hover {
    background-position: 0px -53px;
}

.owl-carousel .next-carousel:hover {
    background-position: -24px -53px;
}